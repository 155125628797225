<template>
  <div id="container">

    <section id="register">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>塾生情報の登録</p>
          </div>
          <div class="step_box flex on">
            <div class="num">2</div>
            <p>ご住所の登録</p>
          </div>
          <div class="step_box flex on">
            <div class="num">3</div>
            <p>パスワード設定</p>
          </div>
          <div class="step_box flex on">
            <div class="num">4</div>
            <p>入力内容の確認</p>
          </div>
          <div class="step_box flex">
            <div class="num">5</div>
            <p>初回月謝の<br>支払いへ進む</p>
          </div>
        </div>

        <form @submit="nextClicked">

          <div class="cts_box">
            <h2 class="sub_ttl"><span>入力内容の確認</span></h2>
            <p class="reg_lead">
          <span class="blue bold">すべての入力内容に間違いがないことをご確認ください。<br>
            すべての内容を確認の後、画面下の「会員登録を完了する」ボタンを押して会員登録を完了してください。</span><br><br>
              入力内容を修正する場合は、該当する項目をクリックして正しい内容を再度入力してください。<br><br>
              <span class="blue bold">「*」のついている箇所は必須項目です。</span>
            </p>

            <form-error-box v-if="errors"/>

            <div class="reg_set">
              <div class="input_set">
                <div class="label">ご希望のクラス・お支払い方法<span>*</span></div>
                <div class="class_check">
                  <div class="select_class">
                    <dl class="flex">
                      <dt>現在ご希望のクラス</dt>
                      <dd class="class">学生科</dd>
                    </dl>
                    <dl class="flex bdr_btm">
                      <dt>お支払い形式</dt>
                      <dd class="payment">月額払い</dd>
                    </dl>
                  </div>
                </div>
              </div>

              <div class="input_set name">
                <div class="label">おなまえ<span>*</span></div>
                <div class="input_fields flex">
                  <div class="field">
                    <div class="label">姓</div>
                    <input type="text" maxlength="16" v-model="new_user.last_name"
                           v-bind:class="{error: validation_errors.last_name}"
                           @input="new_user.last_name = replaceHalfToFull(new_user.last_name)"
                           placeholder="例：山田">
                    <validation-errors :errors="validation_errors.last_name"
                                       v-if="validation_errors.last_name"></validation-errors>
                  </div>
                  <div class="field">
                    <div class="label">名</div>
                    <input type="text" maxlength="16" v-model="new_user.first_name"
                           v-bind:class="{error: validation_errors.first_name}"
                           @input="new_user.first_name = replaceHalfToFull(new_user.first_name)"
                           placeholder="例：太郎">
                    <validation-errors :errors="validation_errors.first_name"
                                       v-if="validation_errors.first_name"></validation-errors>
                  </div>
                </div>
              </div>

              <div class="input_set kana">
                <div class="label">フリガナ<span>*</span></div>
                <div class="input_fields flex">
                  <div class="field">
                    <div class="label">姓（全角カナ）</div>
                    <input type="text" maxlength="16" v-model="new_user.last_name_kana"
                           @input="new_user.last_name_kana = replaceHiraToKana(new_user.last_name_kana)"
                           v-bind:class="{error: validation_errors.last_name_kana}" placeholder="例：ヤマダ">
                    <validation-errors :errors="validation_errors.last_name_kana"
                                       v-if="validation_errors.last_name_kana"></validation-errors>
                  </div>
                  <div class="field">
                    <div class="label">名（全角カナ）</div>
                    <input type="text" maxlength="16" v-model="new_user.first_name_kana"
                           @input="new_user.first_name_kana = replaceHiraToKana(new_user.first_name_kana)"
                           v-bind:class="{error: validation_errors.first_name_kana}" placeholder="例：タロウ">
                    <validation-errors :errors="validation_errors.first_name_kana"
                                       v-if="validation_errors.first_name_kana"></validation-errors>
                  </div>
                </div>
              </div>

              <div class="input_set">
                <div class="label">性別<span>*</span></div>
                <div class="radio" v-bind:class="{error: validation_errors.gender}">
                  <input id="radio01" type="radio" v-model="new_user.gender" value="male">
                  <label for="radio01">男性</label>

                  <input id="radio02" type="radio" v-model="new_user.gender" value="female">
                  <label for="radio02">女性</label>

                  <input id="radio03" type="radio" v-model="new_user.gender" value="secret">
                  <label for="radio03">その他</label>
                </div>
                <validation-errors :errors="validation_errors.gender"
                                   v-if="validation_errors.gender"></validation-errors>
              </div>

              <div class="input_set birth">
                <div class="label">生年月日<span>*</span></div>
                <p>すべての欄に半角数字でご入力下さい。</p>
                <div class="input_fields flex">
                  <div class="field">
                    <input type="text" v-model="new_user.birthday_year"
                           @input="new_user.birthday_year = replaceFullToHalf(new_user.birthday_year)"
                           v-bind:class="{error: validation_errors.birthday_year}" placeholder="年">
                    <validation-errors :errors="validation_errors.birthday_year"
                                       v-if="validation_errors.birthday_year"></validation-errors>
                  </div>
                  <div class="field">
                    <input type="text" v-model="new_user.birthday_month"
                           @input="new_user.birthday_month = replaceFullToHalf(new_user.birthday_month)"
                           v-bind:class="{error: validation_errors.birthday_month}" placeholder="月">
                    <validation-errors :errors="validation_errors.birthday_month"
                                       v-if="validation_errors.birthday_month"></validation-errors>
                  </div>
                  <div class="field">
                    <input type="text" v-model="new_user.birthday_day"
                           @input="new_user.birthday_day = replaceFullToHalf(new_user.birthday_day)"
                           v-bind:class="{error: validation_errors.birthday_day}" placeholder="日">
                    <validation-errors :errors="validation_errors.birthday_day"
                                       v-if="validation_errors.birthday_day"></validation-errors>
                  </div>
                </div>
              </div>

              <div class="input_set birth">
                <div class="label">卒業見込み年/月<span>*</span></div>
                <div class="input_fields flex">
                  <div class="field">
                    <input type="text" v-model="new_user.student_graduation_date_year"
                           @input="new_user.student_graduation_date_year = replaceFullToHalf(new_user.student_graduation_date_year)"
                           v-bind:class="{error: validation_errors.student_graduation_date_year}" placeholder="年">
                    <validation-errors :errors="validation_errors.student_graduation_date_year"
                                       v-if="validation_errors.student_graduation_date_year"></validation-errors>
                  </div>
                  <div class="field">
                    <input type="text" v-model="new_user.student_graduation_date_month"
                           @input="new_user.student_graduation_date_month = replaceFullToHalf(new_user.student_graduation_date_month)"
                           v-bind:class="{error: validation_errors.student_graduation_date_month}" placeholder="月">
                    <validation-errors :errors="validation_errors.student_graduation_date_month"
                                       v-if="validation_errors.student_graduation_date_month"></validation-errors>
                  </div>
                </div>
              </div>

              <div class="input_set email">
                <div class="label">学校で発行されたメールアドレス<span>*</span></div>
                <p>学生科入塾資格の確認のため、学校が発行した ac.jp, edu.jpなどの電子メールアドレスを入力してください。</p>
                <input type="text" class="email" v-bind:class="{error: validation_errors.student_email}"
                       v-model="new_user.student_email" placeholder="hukugan@millioneyes.ac.jp">
                <ValidationErrors :errors="validation_errors.student_email"
                                  v-if="validation_errors.student_email"></ValidationErrors>
              </div>

              <div class="input_set card_select">
                <div class="checkbox">
                  <input id="check03" type="checkbox" v-model="new_user.student_not_email" value="1"
                         v-on:change="studentNotEmailClicked()">
                  <label for="check03" class="bold blue">
                    学校で発行したメールアドレスをお持ちでない場合は、学生証の登録により認証ができます。<br>
                    こちらにチェックをいれて学生証をアップロードしてください。
                  </label>
                </div>
              </div>

              <div class="input_set card" v-if="new_user.student_not_email">
                <div class="label">学生証の登録<span>*</span>（学校発行のメールアドレスとのいずれか必須）</div>
                <p>お手持ちの学生証の画像（jpgまたはpng形式）をアップロードしてください。</p>
                <div class="card_img">
                  <div v-if="new_user.student_card_image_url">
                    <img v-bind:src="new_user.student_card_image_url" id="student_card_image" alt="">
                  </div>
                  <div v-else>
                    <img src="/common/images/card_sample.png" alt="アップロードした画像が表示されます">
                  </div>
                </div>
                <div class="btn_set flex">
                  <div class="btn">
                    <label for="student_card_image1">画像をアップロード</label>
                    <input id="student_card_image1" type="file" accept="image/png,image/jpeg" v-on:change="studentCardImageSelected('student_card_image1')"/>
                  </div>
                  <div class="btn sp">
                    <label for="student_card_image2"><span class="icon_camera">カメラで撮影する</span></label>
                    <input id="student_card_image2" type="file" accept="image/png,image/jpeg" capture="camera" v-on:change="studentCardImageSelected('student_card_image2')"/>
                  </div>
                </div>
                <!--
                <button type="button" class="btn photo_delete" v-on:click="studentCardImageDeleted()">削除</button>
                -->
                <ValidationErrors :errors="validation_errors.student_card_image"
                                  v-if="validation_errors.student_card_image"></ValidationErrors>
              </div>

              <div class="input_set kana">
                <div class="label">ユーザーネーム（表示名）<span>*</span></div>
                <p>複眼経済塾WEBサイト上で他の塾生に表示されるお名前です。ログイン後に「マイページ」で変更できます。</p>
                <input type="text" class="nickname" v-bind:class="{error: validation_errors.humhub_username}"
                       v-model="new_user.humhub_username">
                <ValidationErrors :errors="validation_errors.humhub_username"
                                  v-if="validation_errors.humhub_username"></ValidationErrors>
              </div>

              <div class="input_set email">
                <div class="label">連絡先メールアドレス<span>*</span></div>
                <input type="text" class="email" v-bind:class="{error: validation_errors.email}"
                       v-model="new_user.email">
                <ValidationErrors :errors="validation_errors.email"
                                  v-if="validation_errors.email"></ValidationErrors>
              </div>

              <div class="input_set email">
                <div class="label">連絡先メールアドレス(確認)<span>*</span></div>
                <input type="text" class="email" v-bind:class="{error: validation_errors.email_confirmation}"
                       v-model="new_user.email_confirmation">
                <ValidationErrors :errors="validation_errors.email_confirmation"
                                  v-if="validation_errors.email_confirmation"></ValidationErrors>
              </div>

              <div class="input_set phone">
                <div class="label">連絡先電話番号<span>*</span></div>
                <p>（半角数字で日中連絡の取れるお電話番号をご入力下さい。）</p>
                <input type="tel" v-model="new_user.phone_number" v-bind:class="{error: validation_errors.phone_number}"
                       @input="new_user.phone_number = replaceFullToHalf(new_user.phone_number)"
                       placeholder="例：09012345678">
                <validation-errors :errors="validation_errors.phone_number"
                                   v-if="validation_errors.phone_number"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">郵便番号<span>*</span></div>
                <p>（半角数字）</p>
                <div class="input_fields flex">
                  <div class="field">
                    <input type="text" v-model="new_user.zip" v-bind:class="{error: validation_errors.zip}" name="zip"
                           @input="new_user.zip = replaceFullToHalf(new_user.zip)"
                           placeholder="例：100-1234">
                    <validation-errors :errors="validation_errors.zip" v-if="validation_errors.zip"></validation-errors>
                  </div>
                  <div class="field">
                    <button type="button" class="ajaxzip3" v-on:click="zipSearch">郵便番号から住所を取得</button>
                  </div>
                </div>
              </div>

              <div class="input_set addr">
                <div class="label">都道府県名<span>*</span></div>
                <p>（選択して下さい ※海外にお住まいの方は「日本国外に居住」を選択してください）</p>
                <PrefectureSelect :prefecture="new_user.prefecture" name="prefecture"
                                  v-bind:class="{error: validation_errors.prefecture}"
                                  v-on:changed="prefectureChanged"/>
                <validation-errors :errors="validation_errors.prefecture"
                                   v-if="validation_errors.prefecture"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">市区町村<span>*</span></div>
                <input type="text" v-model="new_user.address1" v-bind:class="{error: validation_errors.address1}"
                       @input="new_user.address1 = replaceHalfToFull(new_user.address1)"
                       name="address1" placeholder="例：新宿区">
                <validation-errors :errors="validation_errors.address1"
                                   v-if="validation_errors.address1"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">地名・番地<span>*</span></div>
                <input type="text" v-model="new_user.address2" v-bind:class="{error: validation_errors.address2}"
                       @input="new_user.address2 = replaceHalfToFull(new_user.address2)"
                       placeholder="例：３丁目１３番地">
                <validation-errors :errors="validation_errors.address2"
                                   v-if="validation_errors.address2"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">建物名・部屋番号<span></span></div>
                <input type="text" v-model="new_user.address3" v-bind:class="{error: validation_errors.address3}"
                       @input="new_user.address3 = replaceHalfToFull(new_user.address3)"
                       placeholder="例：複眼ビル１１１号室">
                <validation-errors :errors="validation_errors.address3"
                                   v-if="validation_errors.address3"></validation-errors>
              </div>

              <div class="input_set">
                <div class="label">パスワード</div>
                <p>（8文字以上の半角英数記号）</p>
                <div class="pswd_box flex">
                  <input v-bind:type="password_type ? 'text' : 'password'" v-model="new_user.password" class="password"
                         @input="new_user.password = replaceFullToHalf(new_user.password)"
                         placeholder="8文字以上">
                  <div class="checkbox">
                    <input id="check01" type="checkbox" v-model="password_type" value="1">
                    <label for="check01">入力内容を表示</label>
                  </div>
                </div>
                <validation-errors :errors="validation_errors.password"
                                   v-if="validation_errors.password"></validation-errors>
              </div>
              <div class="input_set">
                <div class="label">確認のためにもう一度入力</div>
                <p>（8文字以上の半角英数記号）</p>
                <div class="pswd_box flex">
                  <input v-bind:type="password_confirmation_type ? 'text' : 'password'"
                         @input="new_user.password_confirmation_type = replaceFullToHalf(new_user.password_confirmation_type)"
                         v-model="new_user.password_confirmation" class="password" placeholder="8文字以上">
                  <div class="checkbox">
                    <input id="check02" type="checkbox" v-model="password_confirmation_type" value="1">
                    <label for="check02">入力内容を表示</label>
                  </div>
                </div>
                <validation-errors :errors="validation_errors.password_confirmation"
                                   v-if="validation_errors.password_confirmation"></validation-errors>
              </div>

            </div>

            <div class="input_set">
              <div class="checkbox">
                <input id="check03" type="checkbox" v-model="new_user.enable_primary_address" value="1"
                       v-on:change="enablePrimaryAddressClicked()">
                <label for="check03"
                       class="bold blue">郵便物を別住所で受け取りたい方はチェックを入れて、郵便物等の送付先住所指定フォームに別住所を指定してください</label>
              </div>
            </div>

            <div class="reg_set option" v-show="new_user.enable_primary_address">
              <h3 class="min_ttl">郵便物等の送付先住所指定（任意：登録ご住所と同じ場合は入力は不要です）</h3>
              <p class="reg_lead">
                オンラインショップの商品や複眼経済塾からの各種お知らせなど、塾生登録住所以外への送付を希望する場合はご入力ください。<br>塾生登録住所以外で郵便物などを送付する住所のご希望がない場合は入力をスキップしてください。<br><br>
                <span class="blue bold" v-if="false">「*」のついている箇所は必須項目です。</span>
              </p>

              <div class="gray_box">
                <div class="input_set addr">
                  <div class="label">郵便番号</div>
                  <p>（半角数字）</p>
                  <div class="input_fields flex">
                    <div class="field">
                      <input type="text" v-model="new_user.opt_zip" v-bind:class="{error: validation_errors.opt_zip}"
                             @input="new_user.opt_zip = replaceFullToHalf(new_user.opt_zip)"
                             name="opt_zip" placeholder="例：100-1234">
                      <validation-errors :errors="validation_errors.opt_zip"
                                         v-if="validation_errors.opt_zip"></validation-errors>
                    </div>
                    <div class="field">
                      <button type="button" class="ajaxzip3 option" v-on:click="optZipSearch">郵便番号から住所を取得
                      </button>
                    </div>
                  </div>
                </div>

                <div class="input_set addr">
                  <div class="label">都道府県名</div>
                  <p>（選択して下さい ※海外にお住まいの方は「日本国外に居住」を選択してください）</p>
                  <PrefectureSelect :prefecture="new_user.opt_prefecture" name="opt_prefecture"
                                    v-bind:class="{error: validation_errors.opt_prefecture}"
                                    v-on:changed="optPrefectureChanged"/>
                  <validation-errors :errors="validation_errors.opt_prefecture"
                                     v-if="validation_errors.opt_prefecture"></validation-errors>
                </div>

                <div class="input_set addr">
                  <div class="label">市区町村</div>
                  <input type="text" v-model="new_user.opt_address1"
                         @input="new_user.opt_address1 = replaceHalfToFull(new_user.opt_address1)"
                         v-bind:class="{error: validation_errors.opt_address1}" name="opt_address1"
                         placeholder="例：新宿区">
                  <validation-errors :errors="validation_errors.opt_address1"
                                     v-if="validation_errors.opt_address1"></validation-errors>
                </div>

                <div class="input_set addr">
                  <div class="label">地名・番地</div>
                  <input type="text" v-model="new_user.opt_address2"
                         @input="new_user.opt_address2 = replaceHalfToFull(new_user.opt_address2)"
                         v-bind:class="{error: validation_errors.opt_address2}" placeholder="例：３丁目１３番地">
                  <validation-errors :errors="validation_errors.opt_address2"
                                     v-if="validation_errors.opt_address2"></validation-errors>
                </div>

                <div class="input_set addr">
                  <div class="label">建物名・部屋番号<span></span></div>
                  <input type="text" v-model="new_user.opt_address3"
                         @input="new_user.opt_address3 = replaceHalfToFull(new_user.opt_address3)"
                         v-bind:class="{error: validation_errors.opt_address3}" placeholder="例：複眼ビル１１１号室">
                  <validation-errors :errors="validation_errors.opt_address3"
                                     v-if="validation_errors.opt_address3"></validation-errors>
                </div>

              </div>
            </div>

            <p class="alnC mb20">
              すべての項目を確認の上でこちらのボタンを押してください。
            </p>
            <div class="button_wrap">
              <input type="submit" class="submit light arrow" value="次に進む">
            </div>
          </div>
        </form>
      </div><!--//.wrap-->
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import ValidationErrors from '@/components/ValidationErrors.vue'
import FormErrorBox from '@/elements/FormErrorBox.vue'
import PrefectureSelect from "@/components/PrefectureSelect";

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors,
    PrefectureSelect,
    FormErrorBox
  },
  data() {
    if (!this.$store.state.user) {
      this.$router.push({
        name: 'UsersRegistrationStudent',
        params: this.$route.params
      })
      return
    }
    let _new_user = {}
    for (const [key, value] of Object.entries(this.$store.state.user)) {
      _new_user[key] = value
    }
    return {
      new_user: this.$store.state.user,
      validation_errors: {},
      errors: null,
      password_type: false,
      password_confirmation_type: false,
      show_rank: false,
      rank_name: null,
      rank_payment: null,
      no_option: this.$store.state.user.rank !== 'rank_regular_year',
      show_shikihou: (this.$store.state.user.rank !== 'rank_preparatory_month' && this.$store.state.user.rank !== 'rank_regular_plus_year' && this.$store.state.user.rank !== 'rank_regular_plus_month'),
    }
  },

  computed: {},

  mounted() {
  },
  methods: {

    prevClicked() {
      this.$router.push({
        name: 'UsersConfirmationPage3',
        params: this.$route.params
      })
    },
    nextClicked(event) {
      event.preventDefault()
      this.validation_errors = {}
      this.errors = null

      const rank_bk = this.new_user.rank
      const shikiho_bk = this.new_user.shikiho
      if (shikiho_bk && shikiho_bk !== 'none') {
        this.new_user.rank = shikiho_bk
      }

      this.new_user.password_validates = true

      // 学生メアドがあれば学生証はなし
      //if (this.new_user.student_email) {
      //  this.new_user.student_card_image = this.new_user.student_card_image_url = null
      //  this.new_user.student_not_email = false
      //}

      this.axios
          .post(`${this.env.api_base_url}users/create.json`, {
            user: this.new_user,
            campaign_code: this.new_user.campaign_code
          })
          .then(response => {
            this.$router.push({
              name: 'UsersConfirmationStudentFinish',
              params: {token: response.data.token}
            })
          })
          .catch((error) => {
            if (error.response.data.validation_errors) {
              this.new_user.rank = rank_bk
              this.new_user.shikiho = shikiho_bk

              this.scrollTop()
              this.errors = true
              this.validation_errors = error.response.data.validation_errors
            }
            if (error.response.data && error.response.data.error) {
              // TODO: エラー処理
              // this.errors = [error.response.data.error]
            }
            this.$forceUpdate();
          });
    },
    prefectureChanged(prefecture) {
      this.new_user.prefecture = prefecture
      //this.$forceUpdate()
    },
    optPrefectureChanged(prefecture) {
      this.new_user.opt_prefecture = prefecture
      //this.$forceUpdate()
    },
    zipSearch() {
      if (this.new_user.zip) {
        this.addressSearch(this.new_user.zip)
            .then(data => {
              this.new_user.prefecture = data.pref
              this.new_user.address1 = data.address
              this.$forceUpdate()
            })
      }
    },
    enablePrimaryAddressClicked() {
      if (this.new_user.enable_primary_address) {
        this.new_user.opt_zip = this.new_user.zip
        this.new_user.opt_prefecture = this.new_user.prefecture
        this.new_user.opt_address1 = this.new_user.address1
        this.new_user.opt_address2 = this.new_user.address2
        this.new_user.opt_address3 = this.new_user.address3
        this.$forceUpdate()
      }
    },
    optZipSearch() {
      if (this.new_user.opt_zip) {
        this.addressSearch(this.new_user.opt_zip).then(data => {
          this.new_user.opt_prefecture = data.pref
          this.new_user.opt_address1 = data.address
          this.$forceUpdate()
        })
      }
    },

    studentNotEmailClicked() {
    },

    studentCardImageDeleted() {
      this.new_user.student_card_image_url = `${this.env.api_base_url}presets/empty.png`
      this.new_user.remove_student_card_image = true
    },

    studentCardImageSelected(id) {
      const params = new FormData();
      const fileSelectDom = document.getElementById(id)
      params.append('file', fileSelectDom.files[0])
      //params.append('token', this.$route.params.token)
      this.validation_errors.student_card_image = null
      this.new_user.student_card_image_cache = null
      //this.new_user.student_card_image_url = null
      this.new_user.remove_student_card_image = false
      this.axios
          .post(`${this.env.api_base_url}users/student_card.json`, params)
          .then(response => {
            this.new_user.student_card_image = response.data.cache_name
            this.new_user.student_card_image_url = response.data.url
          })
          .catch(error => {
            if (error.response.data && error.response.data.errors) {
              // console.log(error.response.data);
              this.validation_errors.student_card_image = error.response.data.errors
            }
          });
    }
  }
}
</script>
